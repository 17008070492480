import { getGradesData } from "./charts"
import { getGradeColor, getGradeImage, getGradeLabel } from "./grades"
import _ from "lodash"
import {
  getCharacteristicObjectForProblem,
  getCharacteristicObjectForRoute,
} from "./climbs"
import {
  Maybe,
  CharacteristicGroup,
  Characteristic,
} from "src/gql/types/graphql"
import { problemFeatures, routeFeatures } from "../config/features"

export const chartTypes = {
  DISTRIBUTION: "DISTRIBUTION",
  ACTUAL: "ACTUAL",
}

function getDistData({ feature, type, isChildrenDistribution }) {
  return (feature.type === "gym" ? feature.gymAreas : [feature])
    ?.filter((ga) => feature.type !== "gym" || !ga?.isExcludedFromDistribution)
    ?.map((area) =>
      (isChildrenDistribution ? area.childrenDistribution : area.distribution)
        ?.filter((dist) => dist.climbType === type)
        ?.reduce((acc, dist) => {
          // add custom tags (characteristics) to climbs
          const characteristics = dist.characteristics || []
          const climbs = _.times(dist.count, (ind) => ({
            type: dist.climbType,
            grade: dist.grade,
            color: dist.color,
            customCharacteristics: characteristics[ind]?.customChars || [],
            gradeName:
              feature?.isOverlappingGrades || feature?.gym?.isOverlappingGrades
                ? dist.name
                : null,
            gradeColor:
              feature?.isOverlappingGrades || feature?.gym?.isOverlappingGrades
                ? dist.color
                : null,
          }))
          return [...acc, ...climbs]
        }, []),
    )
    .flat()
}

export function getDistributionData({
  feature,
  draftRoutes = [],
  draftProblems = [],
  gradeType,
  hasProblems,
  hasRoutes,
  isShowingGrades,
  isChildrenDistribution = false,
  activeTags = [],
}) {
  const gym = feature.type === "gym" ? feature : feature.gym
  const problemGym = {
    ...gym,
    isShowingGrades: gym?.isShowingGrades || isShowingGrades,
  }
  const routeGym = {
    ...gym,
    isShowingGrades: gym?.isShowingGrades || isShowingGrades,
  }
  const publishedProblemData =
    getGradesData(
      (feature?.problems || []).filter(
        (pr) => !pr.isDraft && !pr.gymArea?.isExcludedFromDistribution,
      ),
      gradeType,
      "problems",
      problemGym,
      activeTags,
    )?.children || []
  const publishedRouteData =
    getGradesData(
      (feature?.routes || []).filter(
        (pr) => !pr.isDraft && !pr.gymArea?.isExcludedFromDistribution,
      ),
      gradeType,
      "routes",
      routeGym,
      activeTags,
    )?.children || []
  const draftProblemData =
    getGradesData(
      draftProblems.filter((pr) => !pr.gymArea?.isExcludedFromDistribution),
      gradeType,
      "problems",
      problemGym,
      activeTags,
    )?.children || []
  const draftRouteData =
    getGradesData(
      draftRoutes.filter((pr) => !pr.gymArea?.isExcludedFromDistribution),
      gradeType,
      "routes",
      routeGym,
      activeTags,
    )?.children || []
  const distProblems =
    getDistData({ feature, type: "problem", isChildrenDistribution }) || []
  const distRoutes =
    getDistData({ feature, type: "route", isChildrenDistribution }) || []
  const distProblemData = hasProblems
    ? (
        getGradesData(
          distProblems,
          gradeType,
          "problems",
          problemGym,
          activeTags,
        )?.children ?? []
      ).map((obj) => ({
        ...obj,
        published:
          publishedProblemData.find((d) => d.value === obj.value)?.y ?? 0,
        draft: draftProblemData.find((d) => d.value === obj.value)?.y ?? 0,
        // draft: 0,
      }))
    : null
  const distRouteData = hasRoutes
    ? (
        getGradesData(distRoutes, gradeType, "routes", routeGym, activeTags)
          ?.children ?? []
      ).map((obj) => ({
        ...obj,
        published: publishedRouteData.find((d) => d.value === obj.value) ?? 0,
        draft: draftRouteData.find((d) => d.value === obj.value)?.y ?? 0,
        // draft: 0,
      }))
    : null
  const activeTagKeys = activeTags.map((tag) => tag.name)
  const problemData = hasProblems
    ? _.uniqBy([...publishedProblemData, ...draftProblemData], "value").map(
        (obj) => {
          const publishedObj = publishedProblemData.find(
            (d) => d.value === obj.value,
          )
          const draftObj = draftProblemData.find((d) => d.value === obj.value)
          const numPublished = publishedObj?.y ?? 0
          const numDraft = draftObj?.y ?? 0
          return {
            ...obj,
            ...activeTagKeys.reduce((acc, key) => {
              return {
                ...acc,
                [key]: (publishedObj?.[key] ?? 0) + (draftObj?.[key] ?? 0),
              }
            }, {}),
            "Rest of the Climbs":
              (publishedObj?.["Rest of the Climbs"] ?? 0) +
              (draftObj?.["Rest of the Climbs"] ?? 0),
            published: numPublished,
            draft: numDraft,
            y: numPublished + numDraft,
          }
        },
      )
    : null
  const routeData = hasRoutes
    ? _.uniqBy([...publishedRouteData, ...draftRouteData], "value").map(
        (obj) => {
          const numPublished =
            publishedRouteData.find((d) => d.value === obj.value)?.y ?? 0
          const numDraft =
            draftRouteData.find((d) => d.value === obj.value)?.y ?? 0
          return {
            ...obj,
            published: numPublished,
            draft: numDraft,
            y: numPublished + numDraft,
          }
        },
      )
    : null
  // add 0 values to problem and route data if have values in distribution data
  if (problemData) {
    distProblemData.forEach((dist) => {
      if (!problemData.find((d) => d.value === dist.value)) {
        problemData.push({
          ...dist,
          published: 0,
          draft: 0,
          y: 0,
        })
      }
    })
  }
  if (routeData) {
    distRouteData.forEach((dist) => {
      if (!routeData.find((d) => d.value === dist.value)) {
        routeData.push({
          ...dist,
          published: 0,
          draft: 0,
          y: 0,
        })
      }
    })
  }

  return {
    problemData: problemData?.sort((a, b) => {
      return a.value - b.value
    }),
    routeData: routeData?.sort((a, b) => {
      return a.value - b.value
    }),
    distProblemData,
    distRouteData,
  }
}

export function addColorData({ data, colors }) {
  return data.map((d) =>
    d.map((pt) => ({
      ...pt,
      gradeColor: getGradeColor(pt.value, colors, pt.gradeColor),
      gradeImage: getGradeImage(pt.value, colors),
    })),
  )
}

export function updateDistribution({ distro, isIncrementing, distribution }) {
  return distribution.map((obj) => {
    return _.isEqual(obj, distro)
      ? {
          ...obj,
          count: isIncrementing ? obj.count + 1 : obj.count - 1,
        }
      : obj
  })
}

export function getDistributionFromDrafts(climbs, isOverlappingGrades) {
  const draftClimbs = climbs.filter((c) => c.isDraft)
  const distribution = draftClimbs.reduce((acc, c) => {
    const distro = acc.find((d) => {
      return (
        d.grade === c.grade &&
        ((c.gradeName && c.gradeName === d.name) ||
          (c.color && c.color === d.color) ||
          !isOverlappingGrades)
      )
    })
    if (distro) {
      distro.count++
    } else {
      acc.push({
        grade: c.grade,
        count: 1,
        climbType: c.type,
        name: c.gradeName,
        color: c.gradeColor,
      })
    }
    return acc
  }, [])
  return distribution
}

export function createDistribution({ gymArea, climbType }) {
  const climbDist = gymArea.distribution.filter(
    (dist) => dist.climbType === climbType,
  )
  return climbDist.reduce((acc, dist) => {
    const gradeLabel =
      getGradeLabel(
        dist.grade,
        climbType,
        gymArea.gym.isFrenchGrades ? "french" : "us",
        gymArea.gym,
        dist.color,
        dist.name,
      ) +
      (gymArea.gym.isHoldColorGrade
        ? climbType === "problem"
          ? " Boulder"
          : " Route"
        : "")
    const pr = {
      name: gradeLabel,
      grade: dist.grade,
      type: climbType,
      setterGrade: dist.grade,
      gradeColor: dist.color,
      gradeName: dist.name,
      gymArea: gymArea,
      gym: gymArea.gym,
      gymId: gymArea.gym._id,
      gymAreaId: gymArea._id,
      location: gymArea.gym.location,
      isDraft: true,
    }
    let prs = Array(dist.count).fill(pr, 0, dist.count)
    const getCharFn =
      climbType === "problem"
        ? getCharacteristicObjectForProblem
        : getCharacteristicObjectForRoute
    if (dist.characteristics?.length) {
      prs = prs.map((pr, ind) => ({
        ...pr,
        ...(dist.characteristics[ind]?.climbChars
          ? getCharFn(pr, dist.characteristics[ind].climbChars)
          : {}),
        customCharacteristics: dist.characteristics[ind]?.customChars ?? [],
      }))
    }
    return [...acc, ...prs]
  }, [])
}

export function getAllCustomChars(gym: import("src/gql/types/graphql").Gym) {
  if (!gym?.characteristicGroups) {
    return []
  }
  return _.clone(gym?.characteristicGroups)
    ?.filter((group) => !group.isDeleted)
    ?.sort((a, b) => {
      return a?.name?.localeCompare(b?.name ?? "")
    })
    ?.reduce(
      (
        acc: { value: string; label: string; isDisabled: boolean }[],
        group: Maybe<CharacteristicGroup>,
      ) => {
        return [
          ...acc,
          {
            value: "divider",
            label: group?.name ?? "",
            isDisabled: true,
          },
          ...(group?.characteristics ?? [])
            ?.filter((ch) => !ch.isDeleted)
            .map((ch: Maybe<Characteristic>) => ({
              value: ch?._id ?? "",
              label: ch?.name ?? "",
              isDisabled: false,
              key: ch?._id,
            })),
        ]
      },
      [],
    )
}

// these can only be true or false
export function getAllChars(climbType: string) {
  const feats =
    climbType === "route"
      ? routeFeatures.filter((f) =>
          ["hold-type", "energy", "movement", "footwork"].includes(f.group),
        )
      : problemFeatures.filter((f) =>
          ["hold-type", "movement", "footwork"].includes(f.group),
        )

  return feats
    .map((f) => ({
      value: f.featureKey,
      label: f.title,
      isDisabled: false,
      key: f.featureKey,
    }))
    .sort((a, b) => {
      return a.label.localeCompare(b.label)
    })
}

export function sortClimbsByGrade(climbs, grades) {
  const gradeLabels = grades.map((g) => g.label || g.name)
  return _.orderBy(climbs, [
    (climb) => {
      return gradeLabels.indexOf(climb?.gradeName)
    },
    "setterGrade",
    "grade",
  ])
}
