import { problemFeatures, routeFeatures } from "../config/features"

export function getCharacteristicObjectForProblem(problem, characteristics) {
  // get characteristic from know characteristic and assign to problem object
  const prChars = characteristics.reduce((acc, char) => {
    const foundFeat = problemFeatures.find((obj) => obj.featureKey === char)
    if (foundFeat) {
      acc[char] = foundFeat.value
    } else {
      // if unknown, add to custom characteristics
      acc.customCharacteristics = [...(acc.customCharacteristics || []), char]
    }
    return acc
  }, {})
  return {
    ...problem,
    ...prChars,
  }
}

export function getCharacteristicObjectForRoute(route, characteristics) {
  // get characteristic from know characteristic and assign to route object
  const roChars = characteristics.reduce((acc, char) => {
    const foundFeat = routeFeatures.find((obj) => obj.featureKey === char)
    if (foundFeat) {
      acc[char] = foundFeat.value
    } else {
      // if unknown, add to custom characteristics
      acc.customCharacteristics = [...(acc.customCharacteristics || []), char]
    }
    return acc
  }, {})

  return {
    ...route,
    ...roChars,
  }
}

export function getProblemCharacteristicsForSetter(problem) {
  const keys = Object.keys(problem)
  const feats = problemFeatures.filter((f) =>
    ["hold-type", "movement", "footwork"].includes(f.group),
  )
  return feats
    .filter((feat) => {
      return (
        keys.includes(feat.featureKey) &&
        problem[feat.featureKey] === feat.value
      )
    })
    .map((feat) => feat.title)
}

export function getRouteCharacteristicsForSetter(route) {
  const keys = Object.keys(route)
  const feats = routeFeatures.filter((f) =>
    ["hold-type", "energy", "movement", "footwork"].includes(f.group),
  )
  return feats
    .filter((feat) => {
      return (
        keys.includes(feat.featureKey) && route[feat.featureKey] === feat.value
      )
    })
    .map((feat) => feat.title)
}

export function getCustomCharacteristics(climb) {
  return climb?.gym?.characteristicGroups?.reduce((acc, cCG) => {
    return [
      ...acc,
      ...cCG.characteristics
        .filter((c) => climb.customCharacteristics.includes(c._id))
        .map((c) => ({
          ...c,
          link: cCG?.link,
          linkTitle: cCG?.linkTitle,
        })),
    ]
  }, [])
}
